import React from 'react';
import '../styles/pages/_purekit.scss';
import Layout from 'src/layout/Layout';
import logo from '../styles/assets/images/purekitPage/PureKit.svg';
import Intro from '../components/sections/intro/Intro';
import RedButton from '../components/buttons/RedButton';
import Title from '../components/title/Title';
import Features from '../components/sections/features/Features';
import FeaturesItem, { FeaturesItemProps } from '../components/sections/features/FeaturesItem';
import PurekitSnippet from '../../content/snippets/purekit.md';
import Sample, { SampleTextContainer, SampleCode } from '../components/sections/sample/Sample';
import Conclusion from '../components/sections/conclusion/Conclusion';
import BlogPost, { BlogPostProps } from '../components/sections/blog/BlogPost';
import BlogPostList from '../components/sections/blog/BlogPostList';
import Cases from '../components/sections/cases/Cases';
import CaseItem, { CaseItemProps } from '../components/sections/cases/CasesItem';
import Benefits, { BenefitsList } from '..//components/sections/benefits/Benefits';
import BenefitsItem, { BenefitsItemProps } from '..//components/sections/benefits/BenefitsItem';
import Standards from '../components/sections/standards/Standards';
import { CardContainer } from '../components/card/Card';
import ZoomImage from '../components/zoom/ZoomImage';
import seoPage from '../../content/seo/purekit.yml';

// Images
import secureIcon from '../styles/assets/images/purekitPage/secure-data.svg';
import passwordIcon from '../styles/assets/images/purekitPage/password-encryption.svg';
import managementIcon from '../styles/assets/images/purekitPage/managment-data.svg';
import dataIcon from '../styles/assets/images/purekitPage/data-encrypt.svg';
import schemeImg from '../styles/assets/images/purekitPage/Scheme-PureKit.png';
import schemeImg2x from '../styles/assets/images/purekitPage/Scheme-PureKit@2x.png';
import IconHealth from '../styles/assets/images/cases/Icon-Health.svg';
import IconFin from '../styles/assets/images/cases/Icon-Fin.svg';
import IconAuto from '../styles/assets/images/cases/Icon-Auto.svg';
import IconCloud from '../styles/assets/images/cases/Icon-Cloud.svg';
import IconIoT from '../styles/assets/images/cases/Icon-IoT.svg';
import IconIndustry from '../styles/assets/images/cases/Icon-Industry.svg';

const FEATURES: FeaturesItemProps[] = [
	{
		href: 'https://developer.virgilsecurity.com/docs/purekit/data-encryption/',
		title: 'Per-user and role-based data encryption',
		iconSrc: dataIcon,
	},
	{
		href: 'https://developer.virgilsecurity.com/docs/purekit/password-encryption/',
		iconSrc: passwordIcon,
		title: 'Password protection against hacking',
	},
	{
		href: 'https://developer.virgilsecurity.com/docs/purekit/fundamentals/',
		iconSrc: managementIcon,
		title: 'Management of data encryption keys',
	},
	{
		href: 'https://developer.virgilsecurity.com/docs/purekit/data-encryption/',
		iconSrc: secureIcon,
		title: 'Secure data and files sharing',
	},
];

const BENEFITS: BenefitsItemProps[] = [
	{
		title: 'End-users control their data and encryption keys',
		children:
			'Data can only be decrypted with a user’s personal encryption key, which itself is only available for the user who knows the password.',
	},
	{
		title: 'More secure than AWS and Google key management systems',
		children:
			'Unlike others, PureKit provides user-specific encryption and a solution for protecting data beyond the inevitable database compromisation.',
	},
	{
		title: 'Stronger than encryption at-rest and TDE',
		children:
			'PureKit provides data encryption with a number of advantages compared to TDE and encryption at-rest, such as more efficient keys infrastructure, PCS, and more.',
	},
	{
		title: 'Provides protection against SQL injection',
		children: 'PureKit is immune to SQL injection because the data is not stored as plaintext.',
	},
	{
		title: 'Encryption occurs regardless of database security',
		children:
			'PureKit provides end-to-end encryption; therefore, data security does not rely on any device, network, or cloud provider.',
	},
	{
		title: 'Post-compromise security for stored data',
		children:
			'PureKit allows instantly invalidate stolen databases by rotating keys and records remotely.',
	},
];

const PUREKIT_POSTS: BlogPostProps[] = [
	{
		title: 'Post-compromise security with Virgil PureKit and MariaDB',
		meta: 'Rebecca Yarbrough — February 2nd, 2020',
		img:
			'/images/posts/Post-Compromise-Security-for-MariaDB-with-Virgil-Security-PureKit-700x265-c-default.jpeg',
		href: '/blog/purekit-mariadb-plugin/',
	},
	{
		title: 'Virgil PureKit: Protect passwords and stored data with post-compromise security',
		meta: 'Rebecca Yarbrough – March 18th, 2019',
		img: '/images/posts/Blog-4-700x265-c-default.jpg',
		href: '/blog/announcing-purekit/',
	},
	{
		title:
			'Marriott’s $123 million mistake: \n A two-step technical guide to avoiding costly GDPR fines',
		meta: 'Rebecca Yarbrough – December 5th, 2019',
		img: '/images/posts/GDPR-Marriott-Breach-Blog-Post-Header-700x265-c-default.jpg',
		href: '/blog/marriott-gdpr-fine/',
	},
];

const ALL_CASES: CaseItemProps[] = [
	{
		title: 'Healthcare',
		description: 'Make your communication platform and backend HIPAA-compliant.',
		icon: IconHealth,
	},
	{
		title: 'Financial',
		description:
			'Manage cardholder data and personal financial information within a PCI DSS-compliant system.',
		icon: IconFin,
	},
	{
		title: 'Automotive',
		description: 'Build IEEE-1609.2-compliant security solutions for protecting V2X communication.',
		icon: IconAuto,
	},
	{
		title: 'Cloud',
		description: 'Protect and manage access to sensitive data in the сloud storage.',
		icon: IconCloud,
	},
	{
		title: 'Internet of Things',
		description: 'Secure IoT devices from manufacturing to the end-user experience.',
		icon: IconIoT,
	},
	{
		title: 'Industry 4.0',
		description:
			'Build a fully protected industrial ecosystem and securely manage it across the stack.',
		icon: IconIndustry,
	},
];

const PurekitPage: React.FC = () => {
	return (
		<Layout seoPage={seoPage} className="purekitPage">
			<Intro>
				<img src={logo} alt="Purekit logo" />
				<h1>PureKit: Data at Rest Security</h1>
				<p>
					Security framework for user passwords and data protection in a database <br /> with
					functionality against online and offline attacks
				</p>
				<RedButton href="https://developer.virgilsecurity.com/docs/purekit/">Start now</RedButton>
			</Intro>
			<Features>
				<Title>
					<h2>Virgil PureKit features</h2>
					<p>Choose your own use case to protect sensitive data at rest.</p>
				</Title>
				<CardContainer>
					{FEATURES.map((fc, index) => {
						return <FeaturesItem {...fc} key={index} />;
					})}
				</CardContainer>
			</Features>
			<section className="instruction">
				<div className="wrapper">
					<Title>
						<h2>How it works</h2>
						<p>
							Data access control based on per-user encryption key management.{' '}
							<a href="/wp-content/uploads/2019/04/PHE-Service-Technical-Paper.pdf" target="_blank">
								Learn more
							</a>
						</p>
					</Title>
					<ZoomImage src={schemeImg2x} disableDesktop={true}>
						<img
							className="instruction-img"
							alt="Data access control based on per-user encryption key management."
							src={schemeImg}
							srcSet={schemeImg2x}
						/>
					</ZoomImage>
				</div>
			</section>
			<Benefits>
				<Title className="dark">
					<h2>Benefits</h2>
					<p>At least 6 reasons to choose Virgil PureKit for data encryption at rest.</p>
				</Title>
				<BenefitsList>
					{BENEFITS.map((bn, index) => (
						<BenefitsItem {...bn} index={index} key={index} />
					))}
				</BenefitsList>
			</Benefits>
			<Standards />
			<Sample>
				<SampleTextContainer>
					<h3>
						From developers <br /> for developers
					</h3>
					<p>
						We provide cross-platform, transparent and easy-to-use libraries. Here are some code
						snippets from our PureKit.
					</p>
					<RedButton href="https://developer.virgilsecurity.com/docs/purekit/">
						Explore documentation
					</RedButton>
				</SampleTextContainer>
				<SampleCode>
					<PurekitSnippet />
				</SampleCode>
			</Sample>
			<BlogPostList>
				{PUREKIT_POSTS.map((post, index) => (
					<BlogPost {...post} key={index} />
				))}
			</BlogPostList>
			<Conclusion>
				<em>Get started</em> now with our easy-to-follow guides.
				<br />
				Start <em>free</em>, pay $0.02/month per user beyond 250 users.
			</Conclusion>
			<Cases>
				{ALL_CASES.map((item, index) => (
					<CaseItem {...item} key={index} />
				))}
			</Cases>
		</Layout>
	);
};

export default PurekitPage;
