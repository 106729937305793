import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Register a user and encrypt their password`}</span>{`
pure.registerUser(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"alice"`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"VeryStrongPassword"`}</span>{`);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Authenticate the user and verify their password`}</span>{`
AuthResult authResult = pure.authenticateUser(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"alice"`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"VeryStrongPassword"`}</span>{`);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Encrypt the user’s SSN (Social Security Number) with their personal key`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`byte`}</span>{`[] cipherText = pure.encrypt(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"alice"`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"SSN"`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"AAA-GG-SSSS"`}</span>{`);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Decrypt the encrypted user’s SSN after the user has been authenticated`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`byte`}</span>{`[] plainText = pure.decrypt(authResult.getGrant(), `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"SSN"`}</span>{`, cipherText);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      